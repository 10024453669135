import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./WinnerCol";
import { campaignClient } from "../../api";
import { byKey, emailRegex, stringRegex } from "../../helpers/utils";
import ModalAddWinner from "../../Components/ModalAddWinner";
import { useForm } from "react-hook-form";
import { showToast } from "../../Components/Toast/ToastManager";
import dayjs from "dayjs";

type RegisterForm = {
  firstName: string;
  lastName: string;
  drawDate: string;
  email: string;
  prize: string;
};

const drawDates = [
  "November 16, 2023",
  "November 23, 2023",
  "November 30, 2023",
  "December 7, 2023",
  "December 14, 2023",
  "December 21, 2023",
];
export const getFormatDate = (date: string, isShort?: boolean) => {
  return dayjs(date).format(isShort ? "YYYY-MM-DD" : "YYYY-MM-DD, hh:mm:ss ");
};
function Winners() {
  const [winnerModal, setWinnerModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const prizeName = "CDN $100 Walmart Gift Card!";
  const {
    isLoading: isLoadingPrizeWinners,
    data: prizeWinners,
    refetch,
  } = useQuery(["getPrizeWinners", {}], async () => {
    const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {});
    return res.prizeWinners;
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RegisterForm>();

  async function resendEmail({ sessionKey, email }: { sessionKey: string; email: string }) {
    try {
      const { result } = await campaignClient.call<{ result: boolean }>("resendClaimEmail", {
        sessionKey,
      });
      if (result) {
        showToast({
          content: `Email send successfully to ${email}. `,
          duration: 3000,
          error: false,
        });
      }
    } catch (e) {
      console.log(e);
      showToast({
        content: "Email was not send. Try again",
        duration: 3000,
        error: true,
      });
    }
  }
  async function handleRegister(data: RegisterForm) {
    if (submitting) {
      return;
    }
    setWinnerModal(false);
    setSubmitting(true);

    try {
      const { sessionKey } = await campaignClient.call<{ sessionKey: string }>("addWinner", {
        ...data,
        drawDate: new Date(data.drawDate),
      });
      if (sessionKey) {
        refetch();
        showToast({
          content: "Winner added successfully.",
          duration: 3000,
          error: false,
        });
      } else {
        showToast({
          content: "Winner was not added. Try again",
          duration: 3000,
          error: true,
        });
      }
    } catch {
      showToast({
        content: "Winner was not added. Try again",
        duration: 3000,
        error: true,
      });
    }
    reset();
    setSubmitting(false);
  }

  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});
    const prizes = byKey(res.prizes, (x) => x.prizeKey);
    return prizes;
  });
  const [filterTable, setFilterTable] = useState("");

  function capitalizeFirstLetter(data: string) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  const getLegalName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null
  ) => {
    if (firstName && lastName) {
      return capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
    } else if (firstName) {
      return capitalizeFirstLetter(firstName);
    } else return "N/A";
  };

  const data = useMemo(() => {
    if (prizeWinners && prizeWinners.length > 0 && prizes) {
      return prizeWinners.map((rp) => {
        const participant = rp.participant;
        const isShowAction =
          !rp.declarationAndRelease?.creationTime &&
          dayjs(participant.metadata?.expiryTime).isAfter(dayjs());

        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          fulfillTime: rp.declarationAndRelease?.creationTime
            ? getFormatDate(rp.declarationAndRelease?.creationTime)
            : "NA",
          status: rp.declarationAndRelease?.creationTime
            ? "Claimed"
            : dayjs().isAfter(dayjs(participant.metadata?.expiryTime))
            ? "Expired"
            : "Unclaim",

          legalName:
            rp.declarationAndRelease?.firstName &&
            getLegalName(rp.declarationAndRelease.firstName, rp.declarationAndRelease?.lastName),
          address: rp.declarationAndRelease?.address,
          address2: rp.declarationAndRelease?.address2,
          city: rp.declarationAndRelease?.city,
          province: rp.declarationAndRelease?.province,
          postal: rp.declarationAndRelease?.postal,
          phone: rp.declarationAndRelease?.phone,
          prize: prizes[rp.prizeKey] && prizes[rp.prizeKey][0].prizeName,
          sessionKey: participant.sessionKey,
          rules: participant.metadata?.rules ? participant.metadata?.rules.toLocaleString() : "NA",

          expiryTime: participant.metadata?.expiryTime
            ? getFormatDate(participant.metadata?.expiryTime)
            : "NA",
          dob: participant.metadata?.dob ? getFormatDate(participant.metadata?.dob, true) : "NA",
          drawDate: participant.metadata?.drawDate
            ? getFormatDate(participant.metadata?.drawDate, true)
            : "NA",
          communicationSend: participant.creationTime
            ? getFormatDate(participant.creationTime)
            : "NA",
          signature: rp.declarationAndRelease?.digitalSignatureUrl,
          action: isShowAction && (
            <button
              className="actionButton"
              onClick={() => {
                resendEmail({ sessionKey: participant.sessionKey, email: participant.email! });
              }}>
              Resend Prize Email
            </button>
          ),
        };
      });
    }
  }, [prizeWinners, prizes]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp: any) => {
        return {
          "First Name": rp.firstName,
          "Last Name": rp.lastName,
          "Email Address": rp.email,
          "Date of birth": rp.dob,
          "Draw date": rp.drawDate,
          "Communication send": rp.communicationSend,
          "Expiry date": rp.expiryTime,
          Prize: rp.prize,
          "Prize Claimed Time": rp.fulfillTime,
          Status: rp.status,
          "Legal Name": rp.legalName ? rp.legalName : "N/A",
          "Address Line 1": rp.address || "N/A",
          "Address Line 2": rp.address2 || "N/A",
          City: rp.city || "N/A",
          Province: rp.province || "N/A",
          Postal: rp.postal || "N/A",
          "Phone Number": rp.phone ? rp.phone : "N/A",
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  if (isLoadingPrizeWinners || isLoadingPrizes || submitting) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Winners</h2>
        <div className="main__actions"></div>
      </div>
      {!isLoadingPrizeWinners && prizeWinners && prizeWinners.length > 0 ? (
        <div className="main__body">
          <div className="tabs">
            <div className="search headerTabs">
              <div className="search__row ">
                <label htmlFor="q" className="hidden">
                  Search
                </label>
                <input
                  type="search"
                  name="winner_search"
                  id="winner_search"
                  value={filterTable}
                  placeholder="Search"
                  className="search__field"
                  onChange={(e) => setFilterTable(e.target.value)}></input>
                <button type="submit" className="search__btn">
                  <svg className="ico-search">
                    <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                  </svg>
                </button>
              </div>
              <div className="search ">
                <div className="search__row ">
                  <button
                    className="btn btn--medium btn--mobile"
                    onClick={() => setWinnerModal(true)}>
                    Add Winner
                  </button>
                </div>
              </div>
              <div className="search ">
                <CSVLink
                  filename={`Campaign-Winner-Report-${new Date().toLocaleDateString()}`}
                  className="btn btn--medium btn--mobile-small"
                  data={reportData ? reportData : ""}
                  asyncOnClick={true}
                  target="_blank">
                  Download Report
                </CSVLink>
              </div>
            </div>
            <div className="tabs__head">
              <nav className="tabs__nav">
                <ul></ul>
              </nav>
            </div>
            <div className="tabs__body mt-5">
              <div className="table table--alt table--tabs table--big">
                <Table
                  columns={columns}
                  data={data ? data : []}
                  tablePageSize={15}
                  filterValue={filterTable}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main__body">
          <div className="tabs">
            <button className="btn btn--medium btn--mobile" onClick={() => setWinnerModal(true)}>
              Add Winner
            </button>
          </div>
          <br />
          <p>Currently there are no any winners to display.</p>
        </div>
      )}

      <ModalAddWinner title="Add Winner" setShowModal={setWinnerModal} showModal={winnerModal}>
        <div className="form">
          <form onSubmit={handleSubmit(handleRegister)}>
            <div className="form__row">
              <label htmlFor="first-name" className="form__label">
                First name
              </label>

              <div className="form__controls">
                <input
                  className="field"
                  placeholder="First name"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "Please enter first name.",
                    },
                    pattern: {
                      value: stringRegex,
                      message: "Please enter a valid first name.",
                    },
                  })}
                />
              </div>
              {errors.firstName && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.firstName.message}
                </p>
              )}
            </div>

            <div className="form__row">
              <label htmlFor="last-name" className="form__label">
                Last name
              </label>
              <div className="form__controls">
                <input
                  className="field"
                  placeholder="Last name"
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: "Please enter last name.",
                    },
                    pattern: {
                      value: stringRegex,
                      message: "Please enter a valid last name.",
                    },
                  })}
                />
              </div>
              {errors.lastName && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.lastName.message}
                </p>
              )}
            </div>
            <div className="form__row">
              <label htmlFor="last-name" className="form__label">
                Email
              </label>
              <div className="form__controls">
                <input
                  className="field"
                  placeholder="Email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Please enter email.",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Please enter a valid email.",
                    },
                  })}
                />
              </div>
              {errors.email && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.email.message}
                </p>
              )}
            </div>
            <div className="form__row">
              <label htmlFor="date" className="form__label">
                Draw date
              </label>
              <div className="form__controls">
                <select
                  className="field"
                  {...register("drawDate", {
                    required: {
                      value: true,
                      message: "Please select draw date.",
                    },
                  })}>
                  <option value="">Select</option>
                  {drawDates.map((date: string, i) => (
                    <option key={i} value={date}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
              {errors.drawDate && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.drawDate.message}
                </p>
              )}
            </div>
            {/* /.form__row */}
            <div className="form__row">
              <label htmlFor="date" className="form__label">
                Prize
              </label>
              <div className="form__controls">
                <select
                  className="field"
                  {...register("prize", {
                    required: {
                      value: true,
                      message: "Please select prize.",
                    },
                  })}>
                  <option value="">Select</option>

                  <option value={prizeName}>{prizeName}</option>
                </select>
              </div>
              {errors.prize && (
                <p className="error-message">
                  <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                  {errors.prize.message}
                </p>
              )}
            </div>
            {/* /.form__row */}
            <div className="form__actions">
              <div className="form-action">
                {" "}
                <button type="button" className="btn-cancel" onClick={() => setWinnerModal(false)}>
                  Cancel
                </button>
                <button className="btn btn-primary small" type="submit">
                  Add Winner
                </button>
              </div>
            </div>
          </form>
        </div>
      </ModalAddWinner>
    </>
  );
}

export default Winners;
