import { useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader } from "../../Components";
import { campaignClient } from "../../api";
import { byKey } from "../../helpers/utils";
import { useForm } from "react-hook-form";
import { getFormatDate } from "../Winners";

type RegisterForm = {
  sessionKey: string | "";
  firstName: string | "";
  lastName: string | "";
  drawDate: string | "";
  email: string | "";
  phone: string | "";
  city: string | "";
  province: string | "";
  dob: string | "";
  signature: string;
  address: string;
  address2: string;
  postal: string;
};
function Participant() {
  const params = useParams();

  const {
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<RegisterForm>();

  const { isLoading: isLoadingWinners, data: prizeWinners } = useQuery(
    "getPrizeWinners",
    async () => {
      const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {
        sessionKey: params.key,
      });

      const stq = res.prizeWinners[0].declarationAndRelease;
      const winner = res.prizeWinners[0].participant;
      const { email, firstName, lastName } = winner;
      const dob = winner.metadata?.dob ? winner.metadata?.dob : "";
      if (stq?.firstName && stq?.lastName && stq?.digitalSignatureUrl) {
        const key = stq.digitalSignatureUrl;

        const { downloadUrl }: { downloadUrl: string } = await campaignClient.call(
          "downloadUserFile",
          {
            key,
          }
        );
        reset({
          firstName: stq.firstName,
          lastName: stq.lastName,
          city: stq?.city!,
          phone: stq?.phone!,
          postal: stq?.postal!,
          province: stq?.province!,
          address: stq?.address!,
          address2: stq?.address2!,
          signature: downloadUrl,
          email: email!,
          dob,
        });
      } else {
        reset({
          email: email!,
          firstName: firstName!,
          lastName: lastName!,
          dob,
        });
      }

      return res.prizeWinners;
    }
  );

  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});

    const prizes = byKey(res.prizes, (x) => x.prizeKey);

    return prizes;
  });

  const { isLoading: isLoadingPools, data: pools } = useQuery("getPrizePools", async () => {
    const res: MainDbReturnTypes.PrizePools = await campaignClient.call("getPrizePools", {});

    return res.prizePools;
  });

  const winners = useMemo(() => {
    if (prizeWinners && prizes && pools) {
      return prizeWinners.map((v) => {
        return {
          prize: prizes[v.prizeKey] ? prizes[v.prizeKey][0].prizeName : "",
          status: v.declarationAndRelease?.creationTime ? "Claimed" : "Unclaimed",
          poolName: pools[0].prizePoolName,
          drawDate: v.participant.metadata?.drawDate
            ? getFormatDate(v.participant.metadata?.drawDate)
            : "NA",
          expiryTime: v.participant.metadata?.expiryTime
            ? getFormatDate(v.participant.metadata?.expiryTime)
            : "NA",
          prizeWinnerKey: v.prizeWinnerKey,
          communicationSend: v.participant.creationTime
            ? getFormatDate(v.participant.creationTime)
            : "NA",
        };
      });
    }
  }, [prizeWinners, prizes, pools]);

  if (isLoadingWinners || isLoadingPrizes || !winners || isLoadingPools) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__head">
        <h2 className="main__title">Participant details</h2>

        <div className="main__actions">
          <Link to="/winners" className="btn-back">
            <svg className="ico-arrow">
              <image xlinkHref="/assets/images/svg/ico-arrow.svg"></image>
            </svg>
          </Link>
        </div>
      </div>
      <div className="main__body main__body--flex">
        <div className="main__form form">
          <form action="">
            <div className="form__body">
              <h4 className="form__title">Info</h4>

              <div className="form__row">
                <label htmlFor="firstName" className="form__label">
                  First name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="firstName"
                    {...register("firstName")}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="lastName" className="form__label">
                  Last name
                </label>

                <div className="form__controls">
                  <input
                    type="text"
                    className="field"
                    id="lastName"
                    {...register("lastName")}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="form__row">
                <label htmlFor="email" className="form__label">
                  Email
                </label>

                <div className="form__controls">
                  <input type="text" className="field" {...register("email")} disabled={true} />
                </div>
              </div>
              {watch("phone") && (
                <>
                  <div className="form__row">
                    <label htmlFor="phone" className="form__label">
                      Phone number
                    </label>

                    <div className="form__controls">
                      <input type="text" className="field" {...register("phone")} disabled={true} />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="address" className="form__label">
                      Address
                    </label>

                    <div className="form__controls">
                      <input
                        type="text"
                        className="field"
                        {...register("address")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="address2" className="form__label">
                      Address 2
                    </label>

                    <div className="form__controls">
                      <input
                        type="text"
                        className="field"
                        {...register("address2")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="city" className="form__label">
                      City
                    </label>

                    <div className="form__controls">
                      <input type="text" className="field" {...register("city")} disabled={true} />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="province" className="form__label">
                      Province
                    </label>

                    <div className="form__controls">
                      <input
                        type="text"
                        className="field"
                        {...register("province")}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form__row">
                    <label htmlFor="dob" className="form__label">
                      Date of birth
                    </label>

                    <div className="form__controls">
                      <input type="text" className="field" {...register("dob")} disabled={true} />
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
        <div className="main__content">
          <div className="table table--alt table--prizing">
            <h4 className="table__title">Prizing</h4>
            <table>
              <colgroup>
                <col className="table__col-1" style={{ width: "17.3%" }} />

                <col className="table__col-2" style={{ width: "17.3%" }} />

                <col className="table__col-3" style={{ width: "17.3%" }} />

                <col className="table__col-4" style={{ width: "auto" }} />

                <col className="table__col-5" style={{ width: "auto" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <span>Draw date</span>
                  </th>
                  <th>
                    <span>Expiry date</span>
                  </th>
                  <th>
                    <span>Communication send</span>
                  </th>

                  <th>
                    <span>Prize name</span>
                  </th>

                  <th>
                    <span>Status</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {winners.map((v) => (
                  <tr key={v.prizeWinnerKey}>
                    <td>{v.drawDate}</td>
                    <td>{v.expiryTime}</td>
                    <td>{v.communicationSend}</td>
                    <td>{v.prize}</td>
                    <td>{v.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {watch("signature") && (
            <div className="table table--alt table--prizing">
              <h4 className="table__title">Signature</h4>
              <div className="signature">
                <br />
                <a href={watch("signature")} target="_blank" rel="noopener noreferrer">
                  Open signature image in new tab
                </a>
              </div>
              <br />
              <img className="signatureWrapper" src={watch("signature")} alt="" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Participant;
