import { Modal } from "react-bootstrap";
import "./css.css";

type ModalAdWinner = {
  showModal: boolean;
  setShowModal: (v: boolean) => void;
  children: JSX.Element | JSX.Element[];
  title: string;
};

function ModalAddWinner({ showModal, setShowModal, children, title }: ModalAdWinner) {
  return (
    <Modal show={showModal} onHide={() => {}}>
      <Modal.Header>
        <Modal.Title>
          <h2 className="modalTitle">
            <b>{title}</b>
          </h2>
        </Modal.Title>{" "}
        <div className="textRight">
          <a className="closePopup" onClick={() => setShowModal(false)}>
            <img src="/assets/images/svg/close.svg" alt="close icon" />
          </a>
        </div>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}

export default ModalAddWinner;
