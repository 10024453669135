import { Row, TableProps } from "react-table";

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "2%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1;
        return nmb;
      },
    },
    {
      Header: "First Name",
      accessor: "firstName",
      width: "6%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].firstName}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      width: "7%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].lastName}
          </a>
        ) : (
          "N/A"
        );
      },
    },

    {
      Header: "Email Address",
      accessor: "email",
      width: "7.7%",
      maxWidth: "60px",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Phone number",
      accessor: "phone",
      width: "6.2%",
    },
    {
      Header: "City",
      accessor: "city",
      width: "5%",
    },
    {
      Header: "Province",
      accessor: "province",
      width: "7.7%",
    },
    {
      Header: "Date of birth",
      accessor: "dob",
      width: "6.2%",
    },
    {
      Header: "Prize won",
      accessor: "prize",
      width: "5%",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].prize ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].prize.split("Wal")[0]}
          </a>
        ) : (
          "N/A"
        );
      },
    },
    {
      Header: "Draw date",
      accessor: "drawDate",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;

        return 0;
      },
      width: "6.2%",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].drawDate
          ? TableInfo.data[TableInfo.row.index].drawDate
          : "";

        return dateString;
      },
    },
    {
      Header: "Communication sent",
      accessor: "communicationSend",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;

        return 0;
      },
      width: "6.2%",
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].communicationSend
          ? TableInfo.data[TableInfo.row.index].communicationSend
          : "";

        return dateString;
      },
    },
    {
      Header: "Expiration date",
      accessor: "expiryTime",
      width: "6.2%",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1;
        if (rowB.values[columnId] > rowA.values[columnId]) return -1;

        return 0;
      },

      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].expiryTime
          ? TableInfo.data[TableInfo.row.index].expiryTime
          : "";

        return dateString;
      },
    },
    {
      Header: "Claim status",
      accessor: "status",
      width: "6%",
    },

    {
      Header: "Action",
      accessor: "action",
      width: "7%",
    },
  ];
  return COLUMNS;
};
