import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import CustomLink from "../CustomLink";

function SideBar() {
  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();
    console.log(user.attributes.profile);
    setProfile(user.attributes.profile);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <nav className="nav sidebar__nav">
        <ul>
          {profile === "Standard" || profile === "Admin" ? (
            <CustomLink to="overview">
              <svg className="ico-bolt" viewBox="0 0 14 16" width="50">
                <use xlinkHref="/assets/images/svg/sprite.svg#ico-bolt" />
              </svg>
              <span>Overview</span>
            </CustomLink>
          ) : (
            <></>
          )}
          <CustomLink to="winners">
            <svg className="ico-star">
              <use xlinkHref="/assets/images/svg/sprite.svg#ico-star" />
            </svg>
            <span>Winners</span>
          </CustomLink>

   
        </ul>
      </nav>
    </>
  );
}

export default SideBar;
